import React, { useState } from "react";
import "../CSS/ResetPassword.css";
import "../CSS/Commons.css";
import { Link, useNavigate } from "react-router-dom";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import { Navigation2 } from "../../landingPage/components/navigation2";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1); // Step 1: Email input, Step 2: Code and password input
  const [emailValid, setEmailValid] = useState(true); // Email validation state
  const [errorMessage, setErrorMessage] = useState(""); // Error message state
  const [successMessage, setSuccessMessage] = useState(""); // Success message state
  const navigate = useNavigate();

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setEmailValid(false);
      return;
    }
    setEmailValid(true);

    // Simulate sending a reset code to the email
    const resetCode = Math.floor(100000 + Math.random() * 900000);
    localStorage.setItem("verificationCode", resetCode);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("code", resetCode);

    const response = await postDataToAPI(
      "/generateEmailVerificationCode.php",
      formData
    );
    if (response === "Code Sent") {
      setStep(2);
      setErrorMessage("");
    } else {
      console.log("Res: ", response);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();
    const storedCode = localStorage.getItem("verificationCode");
    setErrorMessage("");
    setSuccessMessage("");

    if (code === storedCode) {
      if (newPassword !== confirmPassword) {
        setErrorMessage("Passwords do not match. Please try again.");
        return;
      }
      // Reset password logic here
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", newPassword);

      const response = await postDataToAPI("/resetPassword.php", formData);

      if (response === "Success") {
        Swal.fire({
          title: "Success!",
          text: "Password has been reset successfully! You will get redirected to login page",
          icon: "success",
          timer: 3000, // 3 seconds
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        });
        setEmail("");
        setCode("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } else {
      setErrorMessage("Invalid reset code. Please try again.");
    }
  };

  return (
    <div className="reset-container">
      <Navigation2 />
      <div className="reset-box">
        {/* <h2 className="system-name">Nannies Desk</h2> */}
        {step === 1 ? (
          <>
            <form className="reset-form" onSubmit={handleEmailSubmit}>
              <div className="logo-container">
                <img
                  src="/Assets/nanniesDesk.jpg"
                  alt="Logo"
                  className="logo"
                />
              </div>
              <h2 className="reset-title">Reset Password</h2>
              <div className="form-group">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`form-input ${!emailValid ? "input-error" : ""}`}
                  placeholder="Enter your Email"
                />
                {!emailValid && (
                  <p className="error-message">
                    Please enter a valid email address.
                  </p>
                )}
              </div>
              <button type="submit" className="reset-button">
                Send Reset Code
              </button>
            </form>
          </>
        ) : (
          <>
            <h2 className="reset-title">Reset Password</h2>
            <form className="reset-form" onSubmit={handleCodeSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="form-input"
                  placeholder="Enter Code sent to your email"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="form-input"
                  placeholder="set New password"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="form-input"
                  placeholder="Confirm New password"
                  required
                />
              </div>{" "}
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              {successMessage && (
                <p className="success-message">{successMessage}</p>
              )}
              <button type="submit" className="reset-button">
                Reset Password
              </button>
            </form>
          </>
        )}
        <div className="login-links">
          <Link to="/register" className="link-register" id="link">
            Create an account
          </Link>
          <Link to="/login" className="link-resetPassword" id="link">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
