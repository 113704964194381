import React, { useEffect, useState } from "react";
import "../CSS/Profile.css";
import { postDataToAPI } from "../Shared/shared";
import NavBar, { getLocalStorageVariable } from "./NavBar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import ViewConnectModal from "./ViewConnectModal";
import MpesaPaymentModal from "./MpesaPaymentModal";
import { server } from "../configs/configs";
import Swal from "sweetalert2";
import IdVerificationModal from "./IdVerificationModal";

const profilePicFolder = `${server}/profilePics`;

const ViewProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state || {};
  const email = data.email || "";
  const sessionType = data.sessionType || "";

  const [profileData, setProfileData] = useState({});
  const [description, setDescription] = useState("");
  const [chefInfo, setChefInfo] = useState({});
  const [nannyInfo, setNannyInfo] = useState({});
  const [homeOwnerInfo, setHomeOwnerInfo] = useState({});
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(true);
  const [isAboutMeOpen, setIsAboutMeOpen] = useState(true);
  const [showContactInfo, setShowContactInfo] = useState(false);
  const [showViewConnectModal, setShowViewConnectModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [showIdVerificationModal, setShowIdVerificationModal] = useState(false);

  const userEmail = getLocalStorageVariable("userEmail");

  const handleOpenIdVerificationModal = () => setShowIdVerificationModal(true);
  const handleCloseIdVerificationModal = () =>
    setShowIdVerificationModal(false);

  const fetchAdditionalInfoData = async () => {
    if (!email) return;

    const formData = new FormData();
    formData.append("email", email);
    formData.append("Type", "aboutInfo");

    const user = await postDataToAPI("/fetchAdditionalInfoData.php", formData);

    if (user && Array.isArray(user) && user.length > 0) {
      const userDetails = user[0];

      // Use optional chaining to avoid accessing properties of undefined
      setDescription(userDetails.about || "");

      const formData = new FormData();
      formData.append("email", email);
      formData.append("Type", profileData.registrationType);

      const data = await postDataToAPI(
        "/fetchAdditionalInfoData.php",
        formData
      );
      if (data) {
        if (profileData.registrationType === "home-owner") {
          setNannyInfo({});
          setHomeOwnerInfo(data[0] || {});
          setChefInfo({});
        } else if (profileData.registrationType === "house-help") {
          setNannyInfo(data[0] || {});
          setHomeOwnerInfo({});
          setChefInfo({});
        } else if (profileData.registrationType === "chef") {
          setNannyInfo({});
          setHomeOwnerInfo({});
          setChefInfo(data[0] || {});
        }
      }
    } else {
      console.error("No additional info found for user:", user);
      // Optionally, you could set state to defaults or empty objects
      setNannyInfo({});
      setHomeOwnerInfo({});
      setChefInfo({});
      setDescription("");
    }
  };

  const fetchUserData = async () => {
    if (!email) return;

    const users = await postDataToAPI("/fetchAllUsers.php", {});
    const user = users.find((u) => u.email === email);

    if (user) {
      setProfileData(user); // Set profile data first
      setProfilePicture(user.profilePic);
    }

    setLoading(false);
  };

  const checkPaymentStatus = async () => {
    const formData = new FormData();
    formData.append("connectId", profileData.id);
    formData.append("userEmail", userEmail);
    const response = await postDataToAPI("/checkIfConnectPaid.php", formData);

    if (response === "Success") {
      setShowContactInfo(true);
    }
  };

  const handleOpenViewConnectModal = () => {
    setShowViewConnectModal(true);
  };

  const handleCloseViewConnectModal = () => {
    checkPaymentStatus();
    setShowViewConnectModal(false);
  };

  useEffect(() => {
    // Fetch profile data on initial load
    fetchUserData();
    //Check If User Has paid to see contact info
  }, []);

  useEffect(() => {
    if (profileData.registrationType) {
      fetchAdditionalInfoData();
      checkPaymentStatus();
    }
  }, [profileData]);

  const toggleSection = (section) => {
    switch (section) {
      case "personalInfo":
        setIsPersonalInfoOpen(!isPersonalInfoOpen);
        break;
      case "aboutMe":
        setIsAboutMeOpen(!isAboutMeOpen);
        break;
      case "additionalInfo":
        setIsAdditionalInfoOpen(!isAdditionalInfoOpen);
        break;
      default:
        break;
    }
  };

  const handleViewKYCInfo = async () => {
    const formData = new FormData();
    formData.append("userEmail", userEmail);
    formData.append("action", "request");
    formData.append("email", email);

    const response = await postDataToAPI("/sendShareKYC.php", formData);

    if (response === "Email Sent") {
      Swal.fire("success", "KYC Info requested successfully", "success");
    } else {
      Swal.fire("Failed", "KYC Info request Failed", "Failed");
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const displayContactInfo = async () => {
    handleOpenViewConnectModal();
    const formData = new FormData();
    formData.append("userMail", userEmail);
    formData.append("connectEmail", profileData.email);
    formData.append("connectPhoneNumber", profileData.phoneNumber);

    const response = await postDataToAPI("/sendConnectEmails.php", formData);
    if (response === "Email Sent") {
      console.log("We have sent contact info to your email.");
    }
  };

  const onIdVerificationSuccess = () => {
    displayContactInfo();
  };

  const handleOnSuccess = async () => {
    const verifyData = new FormData();
    verifyData.append("userMail", userEmail);
    const verificationRes = await postDataToAPI(
      "/IPRS/checkIfUserIdIsVerified.php",
      verifyData
    );
    if (verificationRes === "Success") {
      const updateIdData = new FormData();
      updateIdData.append("connectId", profileData.id);
      updateIdData.append("userEmail", userEmail);

      const updateRes = await postDataToAPI(
        "/IPRS/updateIdListArray.php",
        updateIdData
      );
      if (updateRes.success) {
        console.log("Updated Id Records");
      }

      displayContactInfo();
    } else {
      handleOpenIdVerificationModal();
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <NavBar />
      <div className="profile-container">
        <h1 className="profile-title">{profileData.fullName}'s Profile</h1>

        {/* Personal Information Section */}
        <div className="profile-section">
          <div
            className="section-header"
            onClick={() => toggleSection("personalInfo")}
          >
            <h2>{profileData.fullName}'s Info</h2>
            {isPersonalInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          {isPersonalInfoOpen && (
            <div className="section-content">
              {/* Profile Picture Section */}
              <div className="profile-picture-container">
                <img
                  src={
                    `${profilePicFolder}/${profilePicture}` ||
                    `${profilePicFolder}/defaultPIC.jpg}`
                  } // Fallback to a default image
                  alt=""
                  className="profile-picture"
                />
              </div>
              <p>
                <strong>Name:</strong> {profileData.fullName || ""}
              </p>
              {showContactInfo ? (
                <>
                  <p>
                    <strong>Email:</strong> {profileData.email || ""}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{" "}
                    {profileData.phoneNumber || ""}
                  </p>
                </>
              ) : (
                <button onClick={() => setIsModalOpen(true)}>
                  Connect with {profileData.fullName}
                </button>
              )}
              <p>
                <strong>Registered As:</strong>{" "}
                {profileData.registrationType || ""}
              </p>
              <p>
                <strong>Date Joined:</strong> {profileData.timeStamp || ""}
              </p>
              <p>
                <strong>Date Of Birth:</strong> {profileData.dateOfBirth || ""}
              </p>
              <p>
                <strong>Country:</strong> {profileData.selectedCountry || ""}
              </p>
              <p>
                <strong>Status:</strong> {profileData.status || ""}
              </p>
              <Button onClick={handleViewKYCInfo}>
                Request {profileData.fullName}'s KYC info
              </Button>
            </div>
          )}
        </div>

        {/* About Me Section */}
        <div className="profile-section">
          <div
            className="section-header"
            onClick={() => toggleSection("aboutMe")}
          >
            <h2>About {profileData.fullName}</h2>
            {isAboutMeOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          {isAboutMeOpen && (
            <div className="section-content">{description}</div>
          )}
        </div>

        {/* Additional Information Section */}
        <div className="profile-section">
          <div
            className="section-header"
            onClick={() => toggleSection("additionalInfo")}
          >
            {profileData.registrationType === "house-help" ||
            profileData.registrationType === "chef" ? (
              <h2>{profileData.fullName}'s Professional Info</h2>
            ) : (
              profileData.registrationType === "home-owner" && (
                <h2>More about {profileData.fullName}</h2>
              )
            )}
            {isAdditionalInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          {isAdditionalInfoOpen && (
            <div className="section-content">
              {profileData.registrationType === "house-help" && (
                <>
                  <p>
                    <strong>Availability:</strong>{" "}
                    {nannyInfo.availability || ""}
                  </p>
                  <p>
                    <strong>Years of Experience:</strong>{" "}
                    {nannyInfo.yearsExperience || ""}
                  </p>
                  <p>
                    <strong>Services Provided:</strong>{" "}
                    {nannyInfo.servicesProvided || ""}
                  </p>
                  <p>
                    <strong>Preferred Age Groups:</strong>{" "}
                    {nannyInfo.preferredAgeGroups || ""}
                  </p>
                  <p>
                    <strong>Languages Spoken:</strong>{" "}
                    {nannyInfo.languagesSpoken || ""}
                  </p>
                  <p>
                    <strong>Employment Status:</strong>{" "}
                    {nannyInfo.employmentStatus || ""}
                  </p>
                </>
              )}
              {profileData.registrationType === "home-owner" && (
                <>
                  <p>
                    <strong>Number of Adults:</strong>{" "}
                    {homeOwnerInfo.familySize
                      ? JSON.parse(homeOwnerInfo.familySize).adults
                      : ""}
                  </p>
                  <p>
                    <strong>Number of Children:</strong>{" "}
                    {homeOwnerInfo.familySize
                      ? JSON.parse(homeOwnerInfo.familySize).children
                      : ""}
                  </p>
                  <p>
                    <strong>Preferred Skills:</strong>{" "}
                    {homeOwnerInfo.preferredSkills || ""}
                  </p>
                  <p>
                    <strong>Preferred Work Schedule:</strong>{" "}
                    {homeOwnerInfo.workSchedule || ""}
                  </p>
                </>
              )}
              {profileData.registrationType === "chef" && (
                <>
                  <p>
                    <strong>Experience:</strong> {chefInfo.chefExperience || ""}
                  </p>
                  <p>
                    <strong>Culinary Skills:</strong>{" "}
                    {chefInfo.culinarySkills || ""}
                  </p>
                  <p>
                    <strong>Certifications:</strong>{" "}
                    {chefInfo.certifications || ""}
                  </p>
                  <p>
                    <strong>Availability:</strong> {chefInfo.availability || ""}
                  </p>
                </>
              )}
            </div>
          )}
        </div>

        <MpesaPaymentModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSuccess={handleOnSuccess}
          connectId={profileData.id}
          regType={sessionType}
        />

        <ViewConnectModal
          isOpen={showViewConnectModal}
          onClose={handleCloseViewConnectModal}
          connectEmail={profileData.email}
          connectPhoneNumber={profileData.phoneNumber}
        />

        <IdVerificationModal
          isOpen={showIdVerificationModal}
          onClose={handleCloseIdVerificationModal}
          onIdVerificationSuccess={onIdVerificationSuccess}
          connectId={profileData.id}
        />
      </div>
    </>
  );
};

export default ViewProfile;
