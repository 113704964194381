import React, { useEffect, useState } from "react";
import { Modal, TextField, Button } from "@mui/material";
import "../CSS/MpesaPaymentModal.css";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import { getLocalStorageVariable } from "./NavBar";

const IdVerificationModal = ({
  isOpen,
  onClose,
  onIdVerificationSuccess,
  connectId,
}) => {
  const [idNumber, setIdNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleIdNumberChange = (e) => {
    setIdNumber(e.target.value);
    if (errorMessage) setErrorMessage(""); // Clear error message on change
  };

  const handleVerifyId = async () => {
    //Get Email
    const userEmail = getLocalStorageVariable("userEmail");
    const formData = new FormData();
    formData.append("userEmail", userEmail);
    formData.append("idNumber", idNumber);
    formData.append("connectId", connectId);

    const response = await postDataToAPI("/IPRS/verifyIds.php", formData);

    if (response.success) {
      onIdVerificationSuccess();
    } else {
      setErrorMessage(response.error);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={(e) => e.stopPropagation()}
      aria-labelledby="payment-modals-title"
    >
      <div className="modals-contents">
        <h2 id="payment-modals-title">Verify Your ID</h2>
        <>
          <p>You are required to verify Id Number to connect</p>
          <TextField
            label="Enter your Id Number"
            value={idNumber}
            onChange={handleIdNumberChange}
            fullWidth
            required
          />
          {/* Display the error message only once */}
          {errorMessage && (
            <p
              className="error-text"
              style={{ color: "red", marginTop: "10px" }}
            >
              {errorMessage}
            </p>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={handleVerifyId}
            style={{ marginTop: "20px" }}
          >
            Verify Id
          </Button>
          {/* Close Button */}
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
            style={{ marginTop: "20px", marginLeft: "10px" }}
          >
            Cancel
          </Button>
        </>
      </div>
    </Modal>
  );
};

export default IdVerificationModal;
