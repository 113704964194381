import React, { useState, useEffect } from "react";
import { postDataToAPI } from "../Shared/shared";
import "../CSS/viewKYC.css";
import NavBar from "./NavBar";
import { server } from "../configs/configs";
import { useLocation } from "react-router-dom";

const imagesFolder = `${server}/kycPics`;

const ViewKYC = () => {
  const location = useLocation(); 
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");  // Retrieve the ID from query parameters

  console.log("Id: ", id);

  const [idNumber, setIdNumber] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [frontIdPicURL, setFrontIdPicURL] = useState("");
  const [backIdPicURL, setBackIdPicURL] = useState("");

  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append("id", id);  // Use the ID in the request

      const response = await postDataToAPI("/getUserKYC.php", formData);
      if (response) {
        const data = response[0];
        setIdNumber(data.idNumber || "");
        setGender(data.gender || "");
        setReligion(data.religion || "");
        setFrontIdPicURL(
          data.frontPic ? `${imagesFolder}/${data.frontPic}` : ""
        );
        setBackIdPicURL(data.backPic ? `${imagesFolder}/${data.backPic}` : "");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (id) {  // Only fetch if ID exists
      fetchData();
    }
  }, [id]);  // Fetch data whenever the ID changes

  return (
    <>
      <NavBar />
      <div className="view-kyc-container">
        <div className="form-group">
          <h2>KYC Information</h2>
        </div>
        <div className="form-group">
          <h4>
            ID Number: <i>{idNumber || "Not specified"}</i>
          </h4>
        </div>
        <div className="form-group">
          <h4>
            Gender: <i>{gender || "Not specified"}</i>
          </h4>
        </div>

        <div className="form-group">
          <h4>
            Religion: <i>{religion || "Not specified"}</i>
          </h4>
        </div>

        <div className="form-group">
          <h3>Front ID Picture:</h3>
        </div>

        <div className="form-group">
          {frontIdPicURL && (
            <img src={frontIdPicURL} alt="Front ID" className="kyc-image" />
          )}
        </div>

        <div className="form-group">
          <h3>Back ID Picture:</h3>
        </div>
        <div className="form-group">
          {backIdPicURL && (
            <img src={backIdPicURL} alt="Back ID" className="kyc-image" />
          )}
        </div>
      </div>
    </>
  );
};

export default ViewKYC;
