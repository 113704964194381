import React, { useState, useEffect } from "react";
import {
  getLocalStorageVariable,
  postDataToAPI,
  postFileDataToAPI,
} from "../Shared/shared";
import "../CSS/KYC.css";
import NavBar from "./NavBar";
import Swal from "sweetalert2";
import { server } from "../configs/configs";
import { Link, useNavigate } from "react-router-dom";

const imagesFolder = `${server}/kycPics`;

const KYC = () => {
  const navigate = useNavigate();
  const [idNumber, setIdNumber] = useState("");
  const [gender, setGender] = useState("");
  const [religion, setReligion] = useState("");
  const [frontIdPic, setFrontIdPic] = useState(null);
  const [backIdPic, setBackIdPic] = useState(null);
  const userEmail = getLocalStorageVariable("userEmail");
  const [frontIdPicURL, setFrontIdPicURL] = useState("");
  const [backIdPicURL, setBackIdPicURL] = useState("");
  const [kycId, setKycId] = useState("");

  const fetchData = async () => {
    try {
      const formData = new FormData();
      formData.append("email", userEmail);
      const response = await postDataToAPI("/getUserKYC.php", formData);

      if (response) {
        const data = response[0];
        setIdNumber(data.idNumber || "");
        setGender(data.gender || "");
        setReligion(data.religion || "");
        setKycId(data.id || "");
        // Set the state for existing images
        setFrontIdPicURL(
          data.frontPic ? `${imagesFolder}/${data.frontPic}` : ""
        );
        console.log("KYC:", kycId);
        setBackIdPicURL(data.backPic ? `${imagesFolder}/${data.backPic}` : "");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [userEmail]);

  const handleGenderChange = (event) => setGender(event.target.value);
  const handleReligionChange = (event) => setReligion(event.target.value);
  const handleIdNumberChange = (event) => setIdNumber(event.target.value);
  const handleFrontIdPicChange = (event) =>
    setFrontIdPic(event.target.files[0]);
  const handleBackIdPicChange = (event) => setBackIdPic(event.target.files[0]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("idNumber", idNumber);
    formData.append("gender", gender);
    formData.append("religion", religion);
    if (frontIdPic) formData.append("frontIdPic", frontIdPic);
    if (backIdPic) formData.append("backIdPic", backIdPic);

    try {
      const response = await postFileDataToAPI("/updateUserKYC.php", formData);
      if (response === "Success") {
        fetchData();
        Swal.fire("Success", "KYC details updated successfully!", "success");
      } else {
        console.log("response: ", response);
        Swal.fire("Failed!", "Failed to Update KYC details", "error");
      }
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleShareKYC = () => {
    console.log("KYC", kycId);
    navigate("/shareKYC", { state: { kycId: kycId } });
  };

  return (
    <>
      <NavBar />
      <form onSubmit={handleSubmit}>
        <div className="formDiv">
          <div>
            <h2>UPDATE KYC INFO</h2>
          </div>
          <div className="form-group">
            <label>ID Number:</label>
            <input
              type="text"
              value={idNumber}
              onChange={handleIdNumberChange}
              required
            />
          </div>

          <div className="form-group">
            <h4>Gender:</h4>
            <label>
              <input
                type="radio"
                value="male"
                checked={gender === "male"}
                onChange={handleGenderChange}
              />
              Male
            </label>
            <label style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                value="female"
                checked={gender === "female"}
                onChange={handleGenderChange}
              />
              Female
            </label>
            <label style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                value="other"
                checked={gender === "other"}
                onChange={handleGenderChange}
              />
              Other
            </label>
          </div>
          <div className="form-group">
            <h4>Religion:</h4>
            <div className="radio-container">
              <label>
                <input
                  type="radio"
                  value="Christian"
                  checked={religion === "Christian"}
                  onChange={handleReligionChange}
                />
                Christian
              </label>
              <label>
                <input
                  type="radio"
                  value="Muslim"
                  checked={religion === "Muslim"}
                  onChange={handleReligionChange}
                />
                Muslim
              </label>
              <label>
                <input
                  type="radio"
                  value="Hindu"
                  checked={religion === "Hindu"}
                  onChange={handleReligionChange}
                />
                Hindu
              </label>
            </div>
          </div>

          <div className="form-group">
            <label>Upload Front ID Picture:</label>
            {frontIdPicURL && (
              <img
                src={frontIdPicURL}
                alt="Front ID"
                style={{ width: "100px", height: "auto" }}
              />
            )}
            <input
              type="file"
              onChange={handleFrontIdPicChange}
              accept="image/*"
            />
          </div>

          <div className="form-group">
            <label>Upload Back ID Picture:</label>
            {backIdPicURL && (
              <img
                src={backIdPicURL}
                alt="Back ID"
                style={{ width: "100px", height: "auto" }}
              />
            )}
            <input
              type="file"
              onChange={handleBackIdPicChange}
              accept="image/*"
            />
          </div>

          <button type="submit">Update</button>
          <br></br>
          <br></br>
          <p onClick={() => handleShareKYC()}>Share KYC Info</p>
        </div>
      </form>
      <div></div>
    </>
  );
};

export default KYC;
