import React, { useEffect, useState } from "react";
import { Modal, TextField, Button } from "@mui/material";
import "../CSS/MpesaPaymentModal.css";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";
import { getLocalStorageVariable } from "./NavBar";

const MpesaPaymentModal = ({
  isOpen,
  onClose,
  onSuccess,
  connectId,
  regType,
}) => {
  const [paymentPhoneNumber, setPaymentPhoneNumber] = useState("");
  const [amountPayable, setAmountPayable] = useState(""); // State for the amount payable
  const [errorMessage, setErrorMessage] = useState(""); // State for managing error message
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Button state to disable it

  // Fetch the amount payable when the modal opens
  useEffect(() => {
    if (isOpen) {
      const fetchAmountPayable = async () => {
        try {
          const response = await postDataToAPI("/fetchAmountPayable.php", {});
          if (response) {
            console.log("Amount Pay: ", response);
            if (regType === "home-owner") {
              setAmountPayable(response.homeOwnerAmount);
            } else {
              setAmountPayable(response.houseHelpAmount);
            }
          } else {
            setErrorMessage("Failed to fetch the payable amount.");
          }
        } catch (error) {
          console.error("Error fetching amount:", error);
          setErrorMessage("Error fetching the amount. Please try again later.");
        }
      };

      fetchAmountPayable();
    }
  }, [isOpen]);

  const handlePhoneNumberChange = (e) => {
    setPaymentPhoneNumber(e.target.value);
    if (errorMessage) setErrorMessage(""); // Clear error message on change
  };

  const onPaymentSubmit = async (paymentPhoneNumber, amountPayable) => {
    const userEmail = getLocalStorageVariable("userEmail");
    let intervalId; // Declare intervalId here to make it accessible throughout onPaymentSubmit
    let timeoutId; // Declare timeoutId to clear timeout on success or failure

    try {
      // Disable the button
      setIsButtonDisabled(true);

      const paymentData = new FormData();
      paymentData.append("phoneNumber", paymentPhoneNumber);
      paymentData.append("amount", amountPayable);
      paymentData.append("connectId", connectId);
      paymentData.append("userEmail", userEmail);

      const rawResponse = await postDataToAPI(
        "/mpesa/processMpesaPayment.php",
        paymentData
      );

      // Extract the JSON portion of the response
      const jsonStartIndex = rawResponse.indexOf("{");
      const paymentResponse = JSON.parse(rawResponse.slice(jsonStartIndex));

      const responseCode = paymentResponse.ResponseCode;

      if (responseCode === "0") {
        // Success case
        const MerchantRequestID = paymentResponse.MerchantRequestID;

        const checkPaymentStatus = async () => {
          const formdata = new FormData();
          formdata.append("MerchantRequestID", MerchantRequestID);

          const confirmResponse = await postDataToAPI(
            "/mpesa/confirmMpesaPayment.php",
            formdata
          );

          if (confirmResponse.status === "Success") {
            Swal.fire({
              title: "Payment Successful!",
              text: "Payment completed. Proceeding with registration...",
              icon: "success",
              timer: 2000,
              showConfirmButton: false,
            });
            clearInterval(intervalId); // Clear interval
            clearTimeout(timeoutId); // Clear timeout to avoid the timeout message
            onClose();
            onSuccess();
          } else if (confirmResponse.status === "Failed") {
            onClose();
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: "Payment Failed, try again later.",
            });
            clearInterval(intervalId); // Clear interval
            clearTimeout(timeoutId); // Clear timeout to avoid the timeout message
          } else if (confirmResponse.status === "Pending") {
            console.log("Pending Payment...");
          } else {
            onClose();
            Swal.fire({
              icon: "error",
              title: "Payment Failed",
              text: "Payment could not be confirmed. Please try again.",
            });
            clearInterval(intervalId); // Clear interval
            clearTimeout(timeoutId); // Clear timeout to avoid the timeout message
          }
        };

        intervalId = setInterval(checkPaymentStatus, 5000); // Start the interval

        timeoutId = setTimeout(() => {
          onClose();
          clearInterval(intervalId); // Clear interval after timeout
          Swal.fire({
            icon: "error",
            title: "Payment Failed",
            text: "Payment confirmation timed out. Please try again.",
          });
        }, 60000); // Timeout after 60 seconds
      } else {
        onClose();
        Swal.fire({
          icon: "error",
          title: "Payment Failed",
          text: "Payment could not be processed.",
        });
      }
    } catch (error) {
      onClose();
      console.error("Payment error:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while processing payment. Please try again.",
      });
    } finally {
      // Re-enable the button after 10 seconds
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 10000); // Re-enable the button after 10 seconds
    }
  };

  const handlePayment = () => {
    // Ensure the phone number is provided
    if (!paymentPhoneNumber) {
      setErrorMessage("Please provide your phone number for payment.");
      return;
    }

    // Validate the phone number length
    const phoneNumberLength = paymentPhoneNumber.trim().length;
    if (phoneNumberLength !== 10) {
      setErrorMessage("Phone number must be exactly 10 digits.");
      return;
    }

    // Check if the first digit is '0' and remove it
    let cleanedPhoneNumber = paymentPhoneNumber.trim();
    if (cleanedPhoneNumber.startsWith("0")) {
      cleanedPhoneNumber = cleanedPhoneNumber.substring(1); // Remove the leading '0'
    }

    // Prefix the phone number with '254'
    const prefixedPhoneNumber = `254${cleanedPhoneNumber}`;

    if (!amountPayable) {
      setErrorMessage("Amount payable not available.");
      return;
    }

    // Pass both the prefixed phone number and amount payable to the parent component
    onPaymentSubmit(prefixedPhoneNumber, amountPayable);
  };

  return (
    <Modal
      open={isOpen}
      onClose={(e) => e.stopPropagation()}
      aria-labelledby="payment-modals-title"
    >
      <div className="modals-contents">
        <h2 id="payment-modals-title">Complete Mpesa Payment</h2>

        {amountPayable !== null ? (
          <>
            <p>
              You are required to pay <b> KES {amountPayable}</b> via M-Pesa to
              connect.
            </p>
            <TextField
              label="Phone Number e.g 0791638771"
              value={paymentPhoneNumber}
              onChange={handlePhoneNumberChange}
              fullWidth
              required
            />
            {/* Display the error message only once */}
            {errorMessage && (
              <p
                className="error-text"
                style={{ color: "red", marginTop: "10px" }}
              >
                {errorMessage}
              </p>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handlePayment}
              style={{ marginTop: "20px" }}
              disabled={isButtonDisabled} // Disable button when in progress
            >
              Make Payment
            </Button>
            {/* Close Button */}
            <Button
              variant="outlined"
              color="secondary"
              onClick={onClose}
              style={{
                marginTop: "20px",
                marginLeft: "10px",
                backgroundColor: "purple",
              }}
            >
              Cancel
            </Button>
          </>
        ) : (
          <p>Loading payment details...</p>
        )}
      </div>
    </Modal>
  );
};

export default MpesaPaymentModal;
