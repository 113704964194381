import React, { useEffect, useState } from "react";
import "../CSS/Profile.css";
import {
  getLocalStorageVariable,
  postDataToAPI,
  postFileDataToAPI,
} from "../Shared/shared";
import NavBar from "./NavBar";
import Swal from "sweetalert2";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AdditionalInfoModal from "./AdditionalInfoModal";
import { Switch } from "@mui/material";
import { server } from "../configs/configs";

const profilePicFolder = `${server}/profilePics`;

const Profile = () => {
  const [profileData, setProfileData] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chefInfo, setChefInfo] = useState({});
  const [nannyInfo, setNannyInfo] = useState({});
  const [homeOwnerInfo, setHomeOwnerInfo] = useState({});
  const [isAdditionalInfoOpen, setIsAdditionalInfoOpen] = useState(true);
  const [loading, setLoading] = useState(true); // Loading state
  const [isActivelyLooking, setIsActivelyLooking] = useState(false); // For chefs
  const [profilePicture, setProfilePicture] = useState("");

  // Collapsible state for sections
  const [isPersonalInfoOpen, setIsPersonalInfoOpen] = useState(true);
  const [isAboutMeOpen, setIsAboutMeOpen] = useState(true);

  const fetchAdditionalInfoData = async () => {
    const userEmail = getLocalStorageVariable("userEmail");

    const formData = new FormData();
    formData.append("email", userEmail);
    formData.append("Type", "aboutInfo");

    const user = await postDataToAPI("/fetchAdditionalInfoData.php", formData);

    if (user && Array.isArray(user) && user.length > 0) {
      const userDetails = user[0];

      // Use optional chaining to avoid accessing properties of undefined
      setDescription(userDetails.about || "");

      const formData = new FormData();
      formData.append("email", userEmail);
      formData.append("Type", profileData.registrationType);

      const data = await postDataToAPI(
        "/fetchAdditionalInfoData.php",
        formData
      );
      if (data) {
        if (profileData.registrationType === "home-owner") {
          setNannyInfo({});
          setHomeOwnerInfo(data[0] || {});
          setChefInfo({});
        } else if (profileData.registrationType === "house-help") {
          setNannyInfo(data[0] || {});
          setHomeOwnerInfo({});
          setChefInfo({});
        } else if (profileData.registrationType === "chef") {
          setNannyInfo({});
          setHomeOwnerInfo({});
          setChefInfo(data[0] || {});
        }
      }
    } else {
      console.error("No additional info found for user:", user);
      // Optionally, you could set state to defaults or empty objects
      setNannyInfo({});
      setHomeOwnerInfo({});
      setChefInfo({});
      setDescription("");
    }
  };

  const fetchUserData = async () => {
    const userEmail = getLocalStorageVariable("userEmail");
    const users = await postDataToAPI("/fetchAllUsers.php", {});
    const user = users.find((u) => u.email === userEmail);

    if (user) {
      setProfileData(user);
      setDescription(user.description || "");
      setProfilePicture(user.profilePic || "");
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    if (profileData.registrationType) {
      fetchAdditionalInfoData();
      if (profileData.activelyLooking === "Active") {
        setIsActivelyLooking(true);
      } else {
        setIsActivelyLooking(false);
      }
    }
  }, [profileData]);

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    const formData = new FormData();
    formData.append("email", profileData.email);
    formData.append("about", description);

    const response = await postDataToAPI("/updateAboutInfo.php", formData);

    if (response === "Success") {
      fetchAdditionalInfoData();
      setIsEditing(false);
      Swal.fire("Success", "Profile updated successfully!", "success");
    } else {
      Swal.fire("Failed!", "Profile update Failed", "error");
    }
  };

  const handleEditAdditionalInfo = () => {
    fetchAdditionalInfoData();
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    fetchAdditionalInfoData();
    setIsModalOpen(false);
  };

  // Toggle section
  const toggleSection = (section) => {
    switch (section) {
      case "personalInfo":
        setIsPersonalInfoOpen(!isPersonalInfoOpen);
        break;
      case "aboutMe":
        setIsAboutMeOpen(!isAboutMeOpen);
        break;
      case "additionalInfo":
        setIsAdditionalInfoOpen(!isAdditionalInfoOpen);
        break;
      default:
        break;
    }
  };

  // Render loading indicator or the main content
  if (loading) {
    return <div>Loading...</div>; // You can customize this loading message
  }

  const updateRegistrationType = (newType) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You are about to update your registration type to ${newType}.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "Cancel",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // Proceed with updating state and calling the API
        const formData = new FormData();
        formData.append("id", profileData.id);
        formData.append("registrationType", newType);
        const response = await postDataToAPI(
          "/updateRegistrationType.php",
          formData
        );

        // Show a success message
        if (response === "Success") {
          fetchUserData();
          Swal.fire(
            "Updated!",
            `Your registration type has been updated to ${newType}.`,
            "success"
          );
        } else {
          Swal.fire("Failed!", `Registration Type Failed`, "error");
        }
      }
    });
  };

  const handleActivelyLookingToggle = async () => {
    // Toggle the state first
    setIsActivelyLooking((prevState) => {
      const newState = !prevState;

      // Determine the new status based on the toggled state
      let newStatus = newState ? "Active" : "Inactive";

      // Send this update to the API
      const formData = new FormData();
      formData.append("email", localStorage.getItem("userEmail"));
      formData.append("newStatus", newStatus);

      postDataToAPI("/updateIsActivelyLooking.php", formData)
        .then((response) => {
          if (response === "Success") {
            Swal.fire({
              title: "Success!",
              text: "Update Success",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "Failed!",
              text: "Failed to update",
              icon: "error",
            });
          }
        })
        .catch((error) => {
          console.error("Error updating status:", error);
          Swal.fire({
            title: "Error!",
            text: "An error occurred while updating.",
            icon: "error",
          });
        });

      return newState; // Return the new state to update `isActivelyLooking`
    });
  };

  // Handle profile picture change
  const handleProfilePictureChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("profilePicture", file);
      formData.append("userEmail", profileData.email);

      const response = await postFileDataToAPI(
        "/updateProfilePicture.php",
        formData
      );

      if (response === "Success") {
        Swal.fire(
          "Success",
          "Profile picture updated successfully!",
          "success"
        );
        fetchUserData();
      } else {
        Swal.fire("Failed!", "Profile picture update failed.", "error");
      }
    }
  };

  return (
    <>
      <NavBar />
      <div className="profile-container">
        <h1 className="profile-title">Profile</h1>

        {/* Personal Information Section */}
        <div className="profile-section">
          <div
            className="section-header"
            onClick={() => toggleSection("personalInfo")}
          >
            <h2>Personal Information</h2>
            {isPersonalInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>

          {isPersonalInfoOpen && (
            <div className="section-content">
              {profileData && (
                <>
                  {/* Profile Picture Section */}
                  <div className="profile-picture-container">
                    <img
                      src={
                        `${profilePicFolder}/${profilePicture}` ||
                        `${profilePicFolder}/defaultPIC.jpg}`
                      } // Fallback to a default image
                      alt=""
                      className="profile-picture"
                    />
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                      className="upload-button"
                      style={{ width: "60%" }}
                    />
                  </div>
                  <p>
                    {/* Toggle Switches */}
                    {profileData.registrationType === "home-owner" && (
                      <div>
                        <strong>Actively Hiring:</strong>
                        <Switch
                          checked={isActivelyLooking}
                          onChange={handleActivelyLookingToggle}
                          color="primary"
                        />
                      </div>
                    )}
                    {(profileData.registrationType === "chef" ||
                      profileData.registrationType === "house-help") && (
                      <div>
                        <strong>Actively Looking for a Job:</strong>
                        <Switch
                          checked={isActivelyLooking}
                          onChange={handleActivelyLookingToggle}
                          color="primary"
                        />
                      </div>
                    )}
                  </p>
                  {/* Dropdown to select Registration Type */}
                  <p style={{ display: "flex", justifyContent: "right" }}>
                    <strong>Registered As:</strong>{" "}
                    <select
                      value={profileData.registrationType}
                      onChange={(e) => {
                        // Handle registration type change here
                        const newRegistrationType = e.target.value;
                        // Assuming you have a function to update the profile or state
                        updateRegistrationType(newRegistrationType);
                      }}
                      style={{ width: "60%" }}
                    >
                      <option value="house-help">House Help</option>
                      <option value="home-owner">Home Owner</option>
                      <option value="chef">Chef</option>
                    </select>
                  </p>
                  <p>
                    <strong>Name:</strong> {profileData.fullName || "N/A"}
                  </p>
                  <p>
                    <strong>Email:</strong> {profileData.email || "N/A"}
                  </p>
                  <p>
                    <strong>Phone Number:</strong>{" "}
                    {profileData.phoneNumber || "N/A"}
                  </p>

                  <p>
                    <strong>Date Joined:</strong>{" "}
                    {profileData.timeStamp || "N/A"}
                  </p>
                  <p>
                    <strong>Date Of Birth:</strong>{" "}
                    {profileData.dateOfBirth || "N/A"}
                  </p>
                  <p>
                    <strong>Country:</strong>{" "}
                    {profileData.selectedCountry || "N/A"}
                  </p>
                  <p>
                    <strong>Status:</strong> {profileData.status || "N/A"}
                  </p>
                </>
              )}
            </div>
          )}
        </div>

        {/* About Me Section */}
        <div className="profile-section">
          <div
            className="section-header"
            onClick={() => toggleSection("aboutMe")}
          >
            <h2>About Me</h2>
            {isAboutMeOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          {isAboutMeOpen && (
            <div className="section-content">
              {isEditing ? (
                <>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Describe yourself..."
                  />
                  <button onClick={handleSave} style={{ marginRight: "5px" }}>
                    Save
                  </button>
                  <button onClick={handleEditToggle}>Cancel</button>
                </>
              ) : (
                <>
                  <p>{description || "No description provided."}</p>
                  <button onClick={handleEditToggle}>Edit Description</button>
                </>
              )}
            </div>
          )}
        </div>

        {/* Additional Information Section */}
        <div className="profile-section">
          <div
            className="section-header"
            onClick={() => toggleSection("additionalInfo")}
          >
            <h2>Additional Information</h2>
            {isAdditionalInfoOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
          {isAdditionalInfoOpen && (
            <div className="section-content">
              {profileData.registrationType === "house-help" && (
                <>
                  <h3>Nanny Professional Details</h3>
                  <p>
                    <strong>Availability:</strong>{" "}
                    {nannyInfo.availability || "N/A"}
                  </p>
                  <p>
                    <strong>
                      Years of Experience in Childcare/Household Services:
                    </strong>{" "}
                    {nannyInfo.yearsExperience || "N/A"}
                  </p>
                  <p>
                    <strong>Type of Services Provided:</strong>{" "}
                    {nannyInfo.servicesProvided || "N/A"}
                  </p>
                  <p>
                    <strong>Preferred Age Groups:</strong>{" "}
                    {nannyInfo.preferredAgeGroups || "N/A"}
                  </p>
                  <p>
                    <strong>Languages Spoken:</strong>{" "}
                    {nannyInfo.languagesSpoken || "N/A"}
                  </p>
                  <p>
                    <strong>Current Employment Status:</strong>{" "}
                    {nannyInfo.employmentStatus || "N/A"}
                  </p>
                </>
              )}
              {profileData.registrationType === "home-owner" && (
                <>
                  <p>
                    <strong>Number of Adults:</strong>{" "}
                    {homeOwnerInfo.familySize
                      ? JSON.parse(homeOwnerInfo.familySize).adults
                      : "N/A"}
                  </p>
                  <p>
                    <strong>Number of Children:</strong>{" "}
                    {homeOwnerInfo.familySize
                      ? JSON.parse(homeOwnerInfo.familySize).children
                      : "N/A"}
                  </p>

                  <h3>Employment Requirements</h3>
                  <p>
                    <strong>Type of Nanny Service Required:</strong>{" "}
                    {homeOwnerInfo.nannyService}
                  </p>
                  <p>
                    <strong>Preferred Experience Level of Nanny:</strong>{" "}
                    {homeOwnerInfo.experienceLevel || "N/A"}
                  </p>
                  <p>
                    <strong>Preferred Skills:</strong>{" "}
                    {homeOwnerInfo.preferredSkills || "N/A"}
                  </p>
                  <p>
                    <strong>Languages Preferred:</strong>{" "}
                    {homeOwnerInfo.familySize
                      ? JSON.parse(homeOwnerInfo.preferredLanguages).language1
                      : "N/A"}
                    ,
                    {homeOwnerInfo.familySize
                      ? JSON.parse(homeOwnerInfo.preferredLanguages).language2
                      : "N/A"}
                  </p>
                  <p>
                    <strong>Work Schedule for Nanny:</strong>{" "}
                    {homeOwnerInfo.workSchedule || "N/A"}
                  </p>

                  <p>
                    <strong>Expected Start Date:</strong>{" "}
                    {homeOwnerInfo.expectedStartDate || "N/A"}
                  </p>
                </>
              )}
              {profileData.registrationType === "chef" && (
                <>
                  <h3>Chef Professional Details</h3>
                  <p>
                    <strong>Experience:</strong>{" "}
                    {chefInfo.chefExperience || "N/A"}
                  </p>
                  <p>
                    <strong>Culinary Skills:</strong>{" "}
                    {chefInfo.culinarySkills || "N/A"}
                  </p>
                  <p>
                    <strong>Certifications:</strong>{" "}
                    {chefInfo.certifications || "N/A"}
                  </p>
                  <p>
                    <strong>Availability:</strong>{" "}
                    {chefInfo.availability || "N/A"}
                  </p>
                  <p>
                    <strong>Preferred Booking Rate:</strong>{" "}
                    {chefInfo.preferredBookingRate || "N/A"}
                  </p>
                </>
              )}
              <button
                className="add-experience-btn"
                onClick={() => setIsModalOpen(true)}
              >
                Edit Additional Information
              </button>
            </div>
          )}
        </div>

        {isModalOpen && (
          <AdditionalInfoModal
            registrationType={profileData.registrationType}
            profileData={""}
            onClose={handleCloseModal}
            onSave={handleEditAdditionalInfo}
          />
        )}
      </div>
    </>
  );
};

export default Profile;
