import React, { useState } from "react";
import "../CSS/Logout.css"; // Import the CSS file
import { postDataToAPI, setLocalStorageVariable } from "../Shared/shared";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";

const Logout = () => {
  const [showModal, setShowModal] = useState(true);
  const navigate = useNavigate();

  const handleConfirm = async () => {
    // End Session from the API
    const formData = new FormData();
    formData.append("email", localStorage.getItem("userEmail"));
    formData.append("action", "endSession");
    const response = await postDataToAPI("/sessions.php", formData);
    if (response) {
      // Clear cache
      setLocalStorageVariable("userEmail", "");
      setLocalStorageVariable("userDetails", "");
      setLocalStorageVariable("userLocation", "");

      setShowModal(false);
      navigate("/login");
    }
  };

  const handleCancel = () => {
    setShowModal(false);
    navigate("/dashboard");
  };
  return (
    <div>
      <NavBar />
      <div className="logout-container">
        <div>
          <h3 className="modalTitle">Are you sure you want to logout?</h3>
          <div>
            {/* Confirm Button */}
            <button onClick={handleConfirm} className="confirmButton">
              Yes, Confirm
            </button>
            {/* Cancel Button */}
            <button onClick={handleCancel} className="cancelButton">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
