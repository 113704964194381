import React from "react";
import "../CSS/ViewConnectModal.css";

const ViewConnectModal = ({ isOpen, onClose, connectEmail, connectPhoneNumber }) => {
  if (!isOpen) return null;

  return (
    <div className="viewConnectLays">
      <div className="viewConnectContents">
        <h3>Connect with this contact Information</h3>
        <p>
          <strong>Email:</strong> {connectEmail}
        </p>
        <p>
          <strong>Phone Number:</strong> {connectPhoneNumber}
        </p>
        <button className="closeBTN" onClick={onClose}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ViewConnectModal;
