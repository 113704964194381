import React, { useState } from "react";
import "../CSS/AdditionalInfoModal.css";
import { postDataToAPI } from "../Shared/shared";
import { getLocalStorageVariable } from "./NavBar";
import Swal from "sweetalert2";

const AdditionalInfoModal = ({ registrationType, profileData, onClose }) => {
  const [availability, setAvailability] = useState(
    profileData.availability || ""
  );
  const [yearsExperience, setYearsExperience] = useState(
    profileData.yearsExperience || 0
  );
  const [servicesProvided, setServicesProvided] = useState(
    profileData.servicesProvided || []
  );
  const [currentStatus, setCurrentStatus] = useState(
    profileData.currentEmploymentStatus || ""
  );
  const [preferredAgeGroups, setPreferredAgeGroups] = useState(
    profileData.preferredAgeGroups || []
  );
  const [languagesSpoken, setLanguagesSpoken] = useState(
    profileData.languagesSpoken || [("", "")]
  );
  const [employmentStatus, setEmploymentStatus] = useState(
    profileData.employmentStatus || ""
  );

  // For home-owner registration type, add relevant states
  const [familySize, setFamilySize] = useState({
    adults: profileData.familySize?.adults || 0,
    children: profileData.familySize?.children || 0,
  });

  const [nannyService, setNannyService] = useState({
    fullTime: false,
    temporary: false,
    substitute: false,
    specializedHelper: false,
    emergencyCare: false,
  });

  const [experienceLevel, setExperienceLevel] = useState("");
  const [preferredSkills, setPreferredSkills] = useState({
    all: false,
    childcare: false,
    housekeeping: false,
    cooking: false,
    tutoring: false,
    specialNeeds: false,
    elderlyCare: false,
  });

  const [preferredLanguages, setPreferredLanguages] = useState({
    language1: "",
    language2: "",
  });

  const [workSchedule, setWorkSchedule] = useState({
    fullTime: false,
    partTime: false,
    temporary: false,
    weekdays: false,
    weekends: false,
    holidays: false,
    specifyHours: "",
  });

  const [expectedStartDate, setExpectedStartDate] = useState("");

  // Chef-specific fields
  const [chefExperience, setChefExperience] = useState(
    profileData.chefExperience || 0
  );
  const [culinarySkills, setCulinarySkills] = useState(
    profileData.culinarySkills || [("", "")]
  );
  const [certifications, setCertifications] = useState(
    profileData.certifications || ""
  );
  const [preferredBookingRate, setPreferredBookingRate] = useState("");

  const handleAgeGroupChange = (ageGroup) => {
    setPreferredAgeGroups((prev) =>
      prev.includes(ageGroup)
        ? prev.filter((group) => group !== ageGroup)
        : [...prev, ageGroup]
    );
  };

  const handleSave = async () => {
    if (registrationType === "house-help") {
      const formData = new FormData();
      formData.append("email", getLocalStorageVariable("userEmail"));
      formData.append("availability", availability);
      formData.append("yearsExperience", yearsExperience);
      formData.append("servicesProvided", servicesProvided);
      formData.append("preferredAgeGroups", preferredAgeGroups);
      formData.append("languagesSpoken", languagesSpoken);
      formData.append("employmentStatus", employmentStatus);

      const response = await postDataToAPI(
        "/updateHouseHelpInfo.php",
        formData
      );

      if (response === "Success") {
        Swal.fire({
          title: "Success!",
          text: "Update Success!",
          icon: "success",
        });
      } else {
        Swal.fire("Failed!", "Update Failed", "error");
      }

      onClose();
    } else if (registrationType === "home-owner") {
      // Create an object to hold only true values
      const filteredNannyService = Object.keys(nannyService).filter(
        (key) => nannyService[key]
      );
      const filteredPreferredSkills = Object.keys(preferredSkills).filter(
        (key) => preferredSkills[key]
      );
      const filteredPreferredLanguages = Object.keys(preferredLanguages).filter(
        (key) => preferredLanguages[key]
      );
      const filteredWorkSchedule = Object.keys(workSchedule).filter(
        (key) => workSchedule[key]
      );

      const formData = new FormData();
      formData.append("email", getLocalStorageVariable("userEmail"));
      formData.append("familySize", JSON.stringify(familySize));
      formData.append("nannyService", filteredNannyService);
      formData.append("preferredSkills", filteredPreferredSkills);
      formData.append("preferredLanguages", JSON.stringify(preferredLanguages));
      formData.append("workSchedule", filteredWorkSchedule);
      formData.append("expectedStartDate", expectedStartDate);
      formData.append("experienceLevel", experienceLevel);

      const response = await postDataToAPI(
        "/updateHomeOwnerInfo.php",
        formData
      );

      if (response === "Success") {
        Swal.fire({
          title: "Success!",
          text: "Update Success!",
          icon: "success",
        });
      } else {
        Swal.fire("Failed!", "Update Failed", "error");
      }

      onClose();
    } else if (registrationType === "chef") {
      const formData = new FormData();
      formData.append("email", getLocalStorageVariable("userEmail"));
      formData.append("chefExperience", chefExperience);
      formData.append("culinarySkills", culinarySkills);
      formData.append("certifications", certifications);
      formData.append("availability", availability);
      formData.append("preferredBookingRate", preferredBookingRate);

      const response = await postDataToAPI("/updateChefInfo.php", formData);

      if (response === "Success") {
        Swal.fire({
          title: "Success!",
          text: "Update Success!",
          icon: "success",
        });
      } else {
        Swal.fire("Failed!", "Update Failed", "error");
      }

      onClose();
    }
  };

  return (
    <div className="modals">
      <h2>
        {registrationType === "house-help" && "House Help"}
        {registrationType === "home-owner" && "Home Owner"}
        {registrationType === "chef" && "Chef"}
      </h2>
      {registrationType === "house-help" ? (
        <>
          <p>Availability:</p>
          <select
            value={availability}
            onChange={(e) => setAvailability(e.target.value)}
          >
            <option value="">Select Availability</option>
            <option value="full-time">Full-time</option>
            <option value="part-time">Part-time</option>
            <option value="temporary">Temporary</option>
            <option value="on-call">On-call</option>
          </select>

          <p>Years of Experience:</p>
          <input
            type="number"
            value={yearsExperience}
            onChange={(e) => setYearsExperience(e.target.value)}
          />

          <p>Type of Services Provided:</p>
          <div>
            {[
              "Childcare",
              "Cooking",
              "Housekeeping",
              "Tutoring",
              "Elderly Care",
              "Special Needs Care",
            ].map((service) => (
              <div key={service}>
                <input
                  type="checkbox"
                  checked={servicesProvided.includes(service)}
                  onChange={() => {
                    if (servicesProvided.includes(service)) {
                      setServicesProvided(
                        servicesProvided.filter((s) => s !== service)
                      );
                    } else {
                      setServicesProvided([...servicesProvided, service]);
                    }
                  }}
                />
                {service}
              </div>
            ))}
          </div>

          {/* Preferred Age Group of Children (if applicable) */}
          <p>Preferred Age Group of Children (if applicable):</p>
          <div>
            {[
              "Infants (0-12 months)",
              "Toddlers (1-3 years)",
              "Preschool (3-5 years)",
              "School-aged (5-12 years)",
              "Teens (13+ years)",
              "Any",
            ].map((ageGroup) => (
              <div key={ageGroup}>
                <input
                  type="checkbox"
                  checked={preferredAgeGroups.includes(ageGroup)}
                  onChange={() => handleAgeGroupChange(ageGroup)}
                />
                {ageGroup}
              </div>
            ))}
          </div>

          {/* Languages Spoken (Fluent) */}
          <p>Languages Spoken (Fluent):</p>
          {languagesSpoken.map((language, index) => (
            <input
              key={index}
              type="text"
              value={language}
              onChange={(e) => {
                const newLanguages = [...languagesSpoken];
                newLanguages[index] = e.target.value;
                setLanguagesSpoken(newLanguages);
              }}
              placeholder={`Language ${index + 1}`}
            />
          ))}

          {/* Add more language input field if needed */}
          <button onClick={() => setLanguagesSpoken([...languagesSpoken, ""])}>
            Add Language
          </button>

          {/* Current Employment Status */}
          <p>Current Employment Status:</p>
          <div>
            {["employed", "unemployed", "freelance"].map((status) => (
              <div key={status}>
                <input
                  type="radio"
                  name="employmentStatus"
                  value={status}
                  checked={employmentStatus === status}
                  onChange={() => setEmploymentStatus(status)}
                />
                {status.charAt(0).toUpperCase() + status.slice(1)}
              </div>
            ))}
          </div>
        </>
      ) : registrationType === "home-owner" ? (
        <>
          {/* Family Size */}
          <label>Family Size:</label>
          <label>Number of Adults:</label>
          <input
            type="number"
            placeholder="Adults"
            value={familySize.adults}
            onChange={(e) =>
              setFamilySize({ ...familySize, adults: e.target.value })
            }
          />
          <label>Number of Children:</label>
          <input
            type="number"
            placeholder="Children"
            value={familySize.children}
            onChange={(e) =>
              setFamilySize({ ...familySize, children: e.target.value })
            }
          />

          {/* Nanny Service Requirements */}
          <h4>Type of Nanny Service Required (Tick all that apply):</h4>
          {[
            "Full Time",
            "Temporary",
            "Substitute",
            "Specialized Helper",
            "Emergency Care",
          ].map((service) => (
            <div key={service}>
              <input
                type="checkbox"
                checked={nannyService[service.toLowerCase().replace(" ", "")]}
                onChange={() =>
                  setNannyService((prev) => ({
                    ...prev,
                    [service.toLowerCase().replace(" ", "")]:
                      !prev[service.toLowerCase().replace(" ", "")],
                  }))
                }
              />
              {service}
            </div>
          ))}

          <label>Experience Level:</label>
          <select
            value={experienceLevel}
            onChange={(e) => setExperienceLevel(e.target.value)}
          >
            <option value="">Select</option>
            <option value="beginner">Beginner</option>
            <option value="experienced">Experienced</option>
            <option value="veteran">Veteran</option>
          </select>

          {/* Preferred Skills */}
          <h4>Preferred Skills (Tick all that apply):</h4>
          {[
            "Childcare",
            "Housekeeping",
            "Cooking",
            "Tutoring",
            "Special Needs",
            "Elderly Care",
          ].map((skill) => (
            <div key={skill}>
              <input
                type="checkbox"
                checked={preferredSkills[skill.toLowerCase()]}
                onChange={() =>
                  setPreferredSkills((prev) => ({
                    ...prev,
                    [skill.toLowerCase()]: !prev[skill.toLowerCase()],
                  }))
                }
              />
              {skill}
            </div>
          ))}

          {/* Preferred Languages */}
          <h4>Preferred Languages:</h4>
          {["language1"].map((languageKey, index) => (
            <input
              key={languageKey}
              type="text"
              value={preferredLanguages[languageKey]}
              onChange={(e) =>
                setPreferredLanguages({
                  ...preferredLanguages,
                  [languageKey]: e.target.value,
                })
              }
              placeholder={`Type Preferred Language`}
            />
          ))}

          {/* Work Schedule */}
          <h4>Work Schedule Preferences (Tick all that apply):</h4>
          {[
            "Full Time",
            "Part Time",
            "Temporary",
            "Weekdays",
            "Weekends",
            "Holidays",
          ].map((schedule) => (
            <div key={schedule}>
              <input
                type="checkbox"
                checked={workSchedule[schedule.toLowerCase().replace(" ", "")]}
                onChange={() =>
                  setWorkSchedule((prev) => ({
                    ...prev,
                    [schedule.toLowerCase().replace(" ", "")]:
                      !prev[schedule.toLowerCase().replace(" ", "")],
                  }))
                }
              />
              {schedule}
            </div>
          ))}
          <label>Specify Hours:</label>
          <input
            type="text"
            value={workSchedule.specifyHours}
            onChange={(e) =>
              setWorkSchedule({ ...workSchedule, specifyHours: e.target.value })
            }
          />
          <label>Expected Start Date:</label>
          <input
            type="date"
            value={expectedStartDate}
            onChange={(e) => setExpectedStartDate(e.target.value)}
          />
        </>
      ) : (
        <>
          {/* Chef-specific fields */}
          <p>Chef Experience (in years):</p>
          <input
            type="number"
            value={chefExperience}
            onChange={(e) => setChefExperience(e.target.value)}
          />

          <p>Culinary Skills:</p>
          {culinarySkills.map((skill, index) => (
            <input
              key={index}
              type="text"
              value={skill}
              onChange={(e) => {
                const newSkills = [...culinarySkills];
                newSkills[index] = e.target.value;
                setCulinarySkills(newSkills);
              }}
              placeholder={`Skill ${index + 1}`}
            />
          ))}
          <button onClick={() => setCulinarySkills([...culinarySkills, ""])}>
            Add Skill
          </button>

          <p>Certifications:</p>
          <input
            type="text"
            value={certifications}
            onChange={(e) => setCertifications(e.target.value)}
          />

          <p>Availability:</p>
          <select
            value={availability}
            onChange={(e) => setAvailability(e.target.value)}
          >
            <option value="">Select Availability</option>
            <option value="full-time">Full-time</option>
            <option value="part-time">Part-time</option>
            <option value="temporary">Temporary</option>
          </select>

          <p>Preferred Booking Rate:</p>
          <input
            type="text"
            value={preferredBookingRate}
            onChange={(e) => setPreferredBookingRate(e.target.value)}
          />
        </>
      )}

      <div className="modal-buttons">
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};

export default AdditionalInfoModal;
