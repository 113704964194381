import React, { useEffect, useState } from "react";
import "../CSS/Register.css";
import { Link, useNavigate } from "react-router-dom";
import {
  getUserLocation,
  handleGeolocationError,
  postDataToAPI,
  setLocalStorageVariable,
} from "../Shared/shared";
import Swal from "sweetalert2";
import axios from "axios";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import { Navigation2 } from "../../landingPage/components/navigation2";

const Register = () => {
  const [fullName, setFullName] = useState(
    localStorage.getItem("fullName") || ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    localStorage.getItem("phoneNumber") || ""
  );
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );
  const [confirmPassword, setConfirmPassword] = useState(
    localStorage.getItem("confirmPassword") || ""
  );
  const [registrationType, setRegistrationType] = useState(
    localStorage.getItem("registrationType") || ""
  );
  const [verificationCode, setVerificationCode] = useState("");
  const [step, setStep] = useState(1); // Step 1: Registration form, Step 2: Code verification form
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [countryCodes, setCountryCodes] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(
    localStorage.getItem("selectedCountry") || ""
  );
  const [termsAccepted, setTermsAccepted] = useState(
    localStorage.getItem("termsAccepted") === "true"
  );
  const [dateOfBirth, setDateOfBirth] = useState(
    localStorage.getItem("dateOfBirth") || ""
  );
  const [address, setAddress] = useState(localStorage.getItem("address") || "");

  const navigate = useNavigate();

  const generateVerificationCode = () => {
    return Math.floor(100000 + Math.random() * 900000).toString();
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();

    // Validate inputs
    if (
      !fullName ||
      !phoneNumber ||
      !email ||
      !selectedCountry ||
      !password ||
      !registrationType ||
      !dateOfBirth ||
      !address
    ) {
      setErrorMessage("All fields are required.");
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      setErrorMessage("Please enter a valid email address.");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }

    const phonePattern = /^\+[1-9]\d{1,14}$/; // E.164 format
    // Check if phoneNumber starts with '0'
    if (phoneNumber.startsWith("0")) {
      setErrorMessage("Phone number cannot start with zero.");
      return;
    }
    // Validate the full phone number format
    if (!phonePattern.test(`${selectedCountryCode.code}${phoneNumber}`)) {
      setErrorMessage("Please enter a valid phone number.");
      return;
    }

    //Check If Email / Phone number / Id Number Already taken
    const formDataa = new FormData();
    formDataa.append("email", email);
    formDataa.append(
      "phoneNumber",
      `${selectedCountryCode.code}${phoneNumber}`
    );

    const recordsAlreadyExist = await postDataToAPI(
      "/checkIfRecordsAlreadyTaken.php",
      formDataa
    );
    if (recordsAlreadyExist !== "success") {
      setErrorMessage(recordsAlreadyExist);
      return;
    }

    const code = generateVerificationCode();
    setLocalStorageVariable("verificationCode", code);

    const formData = new FormData();
    formData.append("email", email);
    formData.append("code", code);

    const response = await postDataToAPI(
      "/generateEmailVerificationCode.php",
      formData
    );
    if (response === "Code Sent") {
      setStep(2);
      setErrorMessage("");
    } else {
      setErrorMessage("Failed to send verification code. Please try again.");
    }
  };
  // Save each field to localStorage on change
  useEffect(() => {
    localStorage.setItem("fullName", fullName);
    localStorage.setItem("phoneNumber", phoneNumber);
    localStorage.setItem("email", email);
    localStorage.setItem("password", password);
    localStorage.setItem("confirmPassword", confirmPassword);
    localStorage.setItem("registrationType", registrationType);
    localStorage.setItem("selectedCountry", selectedCountry);
    localStorage.setItem("termsAccepted", termsAccepted);
    localStorage.setItem("dateOfBirth", dateOfBirth);
    localStorage.setItem("address", address);
  }, [
    fullName,
    phoneNumber,
    email,
    password,
    confirmPassword,
    registrationType,
    selectedCountry,
    termsAccepted,
    dateOfBirth,
    address,
  ]);

  // Clear localStorage after successful registration
  const clearFormStorage = () => {
    localStorage.removeItem("fullName");
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("confirmPassword");
    localStorage.removeItem("registrationType");
    localStorage.removeItem("selectedCountry");
    localStorage.removeItem("termsAccepted");
    localStorage.removeItem("dateOfBirth");
    localStorage.removeItem("address");
  };

  const completeRegistration = async () => {
    try {
      // Default coordinates (fallback)
      let latitude = -1.286389;
      let longitude = 36.817223;

      try {
        // Try to get the user's location
        const location = await getUserLocation();
        latitude = location.latitude;
        longitude = location.longitude;
      } catch (error) {
        // In case of an error (e.g., denied location permission), fallback to default coordinates
        console.error(
          "Location fetch failed, using fallback coordinates.",
          error
        );
        handleGeolocationError(error); // Show appropriate error message to the user
      }

      // Prepare the form data for registration
      const formData = new FormData();
      formData.append("fullName", fullName);
      formData.append("dateOfBirth", dateOfBirth);
      formData.append(
        "phoneNumber",
        `${selectedCountryCode.code}${phoneNumber}`
      );
      formData.append("email", email);
      formData.append("selectedCountry", selectedCountry);
      formData.append("password", password);
      formData.append("address", address);
      formData.append("coordinates", JSON.stringify([latitude, longitude]));
      formData.append("registrationType", registrationType);

      // Submit the registration form
      const response = await postDataToAPI("/signUp.php", formData);

      if (response === "success") {
        clearFormStorage();
        Swal.fire({
          title: "Success!",
          text: "Registration successful! Redirecting to Login Page",
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        });
      } else if (response === "Email Already Taken") {
        setErrorMessage("Email already taken.");
      } else {
        setErrorMessage("Registration failed, please try again later.");
      }
    } catch (error) {
      setErrorMessage("An error occurred. Please try again.");
      console.error("Error:", error);
    }
  };

  const handleCodeSubmit = async (e) => {
    e.preventDefault();

    if (!verificationCode) {
      setErrorMessage("Verification code is required.");
      return;
    }

    const savedCode = localStorage.getItem("verificationCode");
    if (savedCode !== verificationCode) {
      setErrorMessage("Verification code is incorrect.");
      return;
    }

    completeRegistration();
  };

  // Fetch country codes from an API
  useEffect(() => {
    const fetchCountryCodes = async () => {
      try {
        const response = await axios.get("https://restcountries.com/v3.1/all");
        const codes = response.data.map((country) => ({
          code:
            country.idd?.root +
            (country.idd?.suffixes ? country.idd?.suffixes[0] : ""),
          label: country.name.common,
          flag: country.flags?.png, // Use .svg for SVG format or .png for PNG format
        }));
        setCountryCodes(codes);
      } catch (error) {
        console.error("Error fetching country codes:", error);
      }
    };

    fetchCountryCodes();
  }, []);

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  return (
    <div className="register-container">
      <Navigation2 />
      <div className="register-box">
        <div className="scroll-container">
          {step === 1 ? (
            <>
              <form onSubmit={handleEmailSubmit}>
                {/* <div className="logo-container">
                  <img
                    src="/Assets/nanniesDesk.jpg"
                    alt="Logo"
                    className="logo"
                  />
                </div> */}
                <h2 className="system-name">Register</h2>
                <div className="role-selection">
                  <select
                    className="role-dropdown"
                    value={registrationType}
                    onChange={(e) => setRegistrationType(e.target.value)}
                    required // Add the required attribute here
                  >
                    <option value="">Select who to register as</option>
                    <option value="house-help">House Help</option>
                    <option value="home-owner">Home Owner</option>
                    <option value="chef">Chef</option>
                  </select>
                </div>
                <div className="form-group">
                  <TextField
                    type="telf"
                    label="Full Name"
                    variant="outlined"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    required
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="date"
                    label="Date of Birth"
                    variant="outlined"
                    className="form-input"
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="email"
                    label="Email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <Autocomplete
                    value={selectedCountryCode}
                    onChange={(e, newValue) => {
                      setSelectedCountryCode(newValue);
                      setSelectedCountry(newValue ? newValue.label : "");
                    }}
                    options={countryCodes}
                    getOptionLabel={(option) =>
                      `${option.code} - ${option.label}`
                    }
                    renderInput={(params) => (
                      <TextField
                        type="telf"
                        {...params}
                        label="Country Code"
                        variant="outlined"
                        required
                        fullWidth
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <img
                          src={option.flag}
                          alt={`${option.label} flag`}
                          style={{
                            width: "24px",
                            height: "24px",
                            marginRight: "8px",
                          }}
                        />
                        {option.code} - {option.label}
                      </li>
                    )}
                    fullWidth
                    style={{ marginBottom: "16px" }}
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="tel"
                    label="Phone No. (e.g. 791638771)"
                    variant="outlined"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    required
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="telf"
                    label="Enter Nearest town"
                    variant="outlined"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    required
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="password"
                    label="Create Password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                  />
                </div>
                <div className="form-group">
                  <TextField
                    type="password"
                    label="Confirm Password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                    fullWidth
                  />
                </div>
                {/* Terms and Conditions Checkbox */}
                <div
                  className="form-group"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={termsAccepted}
                        onChange={(e) => setTermsAccepted(e.target.checked)}
                        required
                      />
                    }
                    style={{ margin: 0 }}
                  />
                  <span style={{ marginLeft: "8px" }}>
                    {" "}
                    I accept the <Link to="/terms">Terms and Services</Link>
                  </span>
                </div>
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                {successMessage && (
                  <p className="success-message">{successMessage}</p>
                )}
                <button type="submit" className="register-button">
                  Create Account
                </button>
              </form>
            </>
          ) : (
            <>
              <h2 className="register-title">
                Check your email for the verification code
              </h2>
              <form className="register-form" onSubmit={handleCodeSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    className="form-input"
                    placeholder="Enter verification code"
                    required
                  />
                </div>
                {errorMessage && (
                  <p className="error-message">{errorMessage}</p>
                )}
                <button type="submit" className="register-button">
                  Verify Code
                </button>
              </form>
            </>
          )}
        </div>
        <div className="login-links">
          <Link to="/login" className="link-register" id="link">
            Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Register;
