import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import LandingPage from "./landingPage/LandingPage";
import Login from "./APP/Auth/Login";
import Profile from "./APP/components/Profile";
import Dashboard from "./APP/dashboard/Dashboard";
import ViewProfile from "./APP/components/viewProfile";
import AdminDashboard from "./APP/dashboard/AdminDashboard";
import Terms from "./APP/components/Terms";
import Register from "./APP/Auth/Register";
import ResetPassword from "./APP/Auth/ResetPassword";
import Logout from "./APP/components/Logout";
import KYC from "./APP/components/KYC";
import ViewKYC from "./APP/components/viewKYCInfo";
import KYCShare from "./APP/components/ShareKYC";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/register" element={<Register />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/adminDashboard" element={<AdminDashboard />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/ViewProfile" element={<ViewProfile />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/kyc" element={<KYC />} />
          <Route path="/viewKycInfo" element={<ViewKYC />} />
          <Route path="/shareKYC" element={<KYCShare />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
