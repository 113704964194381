import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../CSS/KYCShare.css"; // Add relevant CSS styles here
import Swal from "sweetalert2";
import NavBar from "./NavBar";
import { useLocation } from "react-router-dom";
import { UIUrl } from "../configs/configs";
import { postDataToAPI } from "../Shared/shared";

const KYCShare = () => {
  const location = useLocation();
  const data = location.state || {};
  const kycId = data.kycId || "";

  const [recipientEmail, setRecipientEmail] = useState("");
  const shareLink = `${UIUrl}/viewKycInfo?id=` + kycId;

  const handleEmailChange = (e) => {
    setRecipientEmail(e.target.value);
  };

  const handleShare = async () => {
    if (!recipientEmail) {
      Swal.fire("Error", "Please enter a recipient email.", "error");
      return;
    }
    // Replace the following alert with a real API call to send the KYC info via email
    const formData = new FormData();
    formData.append("email", recipientEmail);
    formData.append("action", "share");
    formData.append("message", shareLink);

    const response = await postDataToAPI("/sendShareKYC.php", formData);

    if (response === "Email Sent") {
      Swal.fire("success", "KYC sharing Success", "success");
    } else {
      Swal.fire("Failed", "KYC sharing Failed", "Failed");
    }

    setRecipientEmail("");
  };

  const copyLink = () => {
    navigator.clipboard.writeText(shareLink).then(() => {
      Swal.fire("Copied", "Link copied to clipboard.", "success");
    });
  };

  return (
    <>
      <NavBar />
      <div className="kyc-share-container">
        <h2>Share Your KYC Information</h2>

        {/* Email Sharing Section */}
        <div className="email-share-section">
          <label htmlFor="recipientEmail">Recipient Email:</label>
          <input
            type="email"
            id="recipientEmail"
            value={recipientEmail}
            onChange={handleEmailChange}
            placeholder="Enter recipient's email"
            required
          />
          <button onClick={handleShare}>Share via Email</button>
        </div>

        {/* Copyable Link Section */}
        <div className="link-share-section">
          <p>Or share using this link:</p>
          <div className="link-container">
            <Link to="/shareKYC" target="_blank">
              {`${UIUrl}/viewKycInfo?id=` + kycId}
            </Link>
            <button onClick={copyLink}>Copy Link</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KYCShare;
