import React, { useState, useEffect } from "react";
import NavBar from "../components/NavBar";
import { Divider, TextField, Button, Tabs, Tab } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid"; // Import MUI DataGrid
import "../CSS/AdminDashboard.css";
import { postDataToAPI } from "../Shared/shared";
import Swal from "sweetalert2";

const AdminDashboard = () => {
  const [houseHelpAmountPayable, setHouseHelpAmountPayable] = useState(0);
  const [homeOwnersAmountPayable, setHomeOwnersAmountPayable] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [amounts, setAmounts] = useState({
    houseHelpAmount: houseHelpAmountPayable,
    homeOwnerAmount: homeOwnersAmountPayable,
  });
  const [users, setUsers] = useState([]);

  // Fetch all users
  const fetchAllUsers = async () => {
    try {
      const response = await postDataToAPI("/fetchAllUsers.php", {});
      if (response) {
        setUsers(response);
      } else {
        setErrorMessage("Failed to fetch users.");
      }
    } catch (error) {
      setErrorMessage("Error fetching users. Please try again later.");
    }
  };

  // Fetch amount payable from API
  const fetchAmountPayable = async () => {
    try {
      const response = await postDataToAPI("/fetchAmountPayable.php", {});
      if (response.houseHelpAmount) {
        setHouseHelpAmountPayable(response.houseHelpAmount);
        setHomeOwnersAmountPayable(response.homeOwnerAmount);
        setAmounts({
          houseHelpAmount: response.houseHelpAmount,
          homeOwnerAmount: response.homeOwnerAmount,
        });
      } else {
        setErrorMessage("Failed to fetch payable amount.");
      }
    } catch (error) {
      setErrorMessage("Error fetching amount. Please try again later.");
    }
  };

  useEffect(() => {
    fetchAmountPayable();
    fetchAllUsers();
  }, []);

  // Handle amount changes for the amounts payable
  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    setAmounts({ ...amounts, [name]: value });
  };

  // Handle saving amounts
  const handleSaveAmounts = async (e) => {
    e.preventDefault();
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the amounts payable?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      setHouseHelpAmountPayable(amounts.houseHelpAmount);
      setHomeOwnersAmountPayable(amounts.homeOwnerAmount);

      const formData = new FormData();
      formData.append("houseHelpAmount", amounts.houseHelpAmount);
      formData.append("homeOwnerAmount", amounts.homeOwnerAmount);

      const response = await postDataToAPI(
        "/modifyPayableAmounts.php",
        formData
      );

      if (response === "Success") {
        Swal.fire({
          title: "Success!",
          text: "Amount payable updated successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to update the amounts.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  // Handle user status change (Activate/Deactivate)
  const handleUserStatusChange = async (id, status, email) => {
    const newStatus = status === "Active" ? "Inactive" : "Active";
    const result = await Swal.fire({
      title: `Are you sure you want to ${
        newStatus === "Active" ? "activate" : "deactivate"
      } this user?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, update it!",
      cancelButtonText: "No, cancel!",
    });

    if (result.isConfirmed) {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("status", newStatus);

      const response = await postDataToAPI("/updateUserStatus.php", formData);

      const formDataa = new FormData();
      if (newStatus === "Active") {
        formDataa.append("action", "activateUser");
      } else if (newStatus === "Inactive") {
        formDataa.append("action", "deactivateUser");
      }
      formDataa.append("email", email);
      const responsee = await postDataToAPI("/sendEmails.php", formDataa);

      if (response === "Success") {
        if (responsee === "Email Sent") {
          fetchAllUsers();
          Swal.fire({
            title: "Success!",
            text: `User has been ${
              newStatus === "Active" ? "activated" : "deactivated"
            } successfully.`,
            icon: "success",
            confirmButtonText: "OK",
          });
        }
      } else {
        Swal.fire({
          title: "Error",
          text: "Failed to update user status.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  const userColumns = [
    { field: "fullName", headerName: "Full Name", width: 200 },
    { field: "age", headerName: "Age", width: 100 },
    { field: "phoneNumber", headerName: "Phone Number", width: 150 },
    { field: "email", headerName: "Email", width: 200 },
    { field: "registrationType", headerName: "registrationType", width: 200 },
    { field: "status", headerName: "Status", width: 100 },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={params.row.status === "Active" ? "secondary" : "primary"}
          onClick={() =>
            handleUserStatusChange(
              params.row.id,
              params.row.status,
              params.row.email
            )
          }
        >
          {params.row.status === "Active" ? "Deactivate" : "Activate"}
        </Button>
      ),
    },
  ];

  return (
    <div className="admin-dashboardd">
      <NavBar />
      <div className="dashboard-contents">
        <h2>NanniesDesk Management Panel</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}

        <Tabs
          value={activeTab}
          onChange={(event, newValue) => setActiveTab(newValue)}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="Users List" />
          <Tab label="Modify Amounts Payable" />
        </Tabs>

        {activeTab === 0 && (
          <div style={{ height: "calc(100vh - 150px)", width: "100%" }}>
            <DataGrid
              rows={users}
              columns={userColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection={false}
            />
          </div>
        )}

        {activeTab === 1 && (
          <form className="forms" onSubmit={handleSaveAmounts}>
            <h3>Modify Amounts Payable</h3>
            <div>
              <TextField
                label="House Help Amount"
                name="houseHelpAmount"
                type="number"
                value={amounts.houseHelpAmount}
                onChange={handleAmountChange}
                required
              />
            </div>
            <br />
            <div>
              <TextField
                label="Home Owners Amount"
                name="homeOwnerAmount"
                type="number"
                value={amounts.homeOwnerAmount}
                onChange={handleAmountChange}
                required
              />
            </div>
            <br />
            <Button type="submit" variant="contained" color="primary">
              Save Amounts
            </Button>
            <Divider style={{ margin: "20px 0" }} />
          </form>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
