import React from "react";
import "../CSS/terms.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Navigation2 } from "../../landingPage/components/navigation2";

const Terms = () => {
  const navigate = useNavigate();

  return (
    <div className="terms-container">
      <Navigation2 />
      <div className="terms-header">
        <h3>Terms and Conditions</h3>
      </div>

      <div className="terms-content">
        <div className="term">
          <span className="term-icon">✔</span>
          <p>
            By signing below, I confirm that I agree to all terms listed here.
          </p>
        </div>
        <div className="term">
          <span className="term-icon">✔</span>
          <p>
            I understand that the information may be used by Nannies Desk to
            facilitate the nanny hiring process.
          </p>
        </div>
        <div className="term">
          <span className="term-icon">✔</span>
          <p>
            I also consent to the collection, storage, and use of my data in
            accordance with Nannies Desk's Privacy Policy and Kenya's Data
            Protection Act 2019.
          </p>
        </div>
      </div>

      <div className="terms-footer">
        <Button
          style={{ backgroundColor: "#642D92" }}
          variant="outlined"
          onClick={() => navigate("/register")}
        >
          Back
        </Button>
      </div>
    </div>
  );
};

export default Terms;
