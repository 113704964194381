import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { postDataToAPI } from "../Shared/shared";

export const getLocalStorageVariable = (name) => {
  const value = localStorage.getItem(name);
  return value;
};

const NavBar = () => {
  const navigate = useNavigate();
  const userEmail = getLocalStorageVariable("userEmail");

  //Automatically Logout user if User their session expires
  const checkSessionStatus = async () => {
    const formData = new FormData();
    formData.append("action", "checkSession");
    formData.append("email", userEmail);

    try {
      const response = await postDataToAPI("/sessions.php", formData);
      if (response === 1) {
        // Assuming "1" means session is active
        console.log("Session Variable: ", response);
      } else {
        // console.log("Session has expired.", response);
        // Handle session expiration (e.g., redirect to login)
        // navigate("/");
      }
    } catch (error) {
      console.error("Error checking session status:", error);
    }
  };

  useEffect(() => {
    // Check session status immediately on component mount
    checkSessionStatus();

    // Set interval to check every 5 minutes (300000 milliseconds)
    const intervalId = setInterval(() => {
      checkSessionStatus();
    }, 300000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const handleHomeClick = async () => {
    if (userEmail) {
      try {
        const userData = await postDataToAPI("/fetchAllUsers.php", {});
        const user = userData.find((user) => user.email === userEmail);

        if (user) {
          navigate("/dashboard");
        } else {
          console.error("User not found.");
          navigate("/"); // Redirect if user is not found
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }
  };

  return (
    <>
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="/dashboard">
              Nannies Desk
            </a>{" "}
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <Link onClick={handleHomeClick}>Home</Link>
              </li>
              <li>
                <Link to="/profile">Profile</Link>
              </li>
              <li>
                <Link to="/kyc">KYC</Link>
              </li>
              <li>
                <Link to="/logout" style={{ color: "red" }}>
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
