import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import "../CSS/Dashboard.css";
import {
  calculateDistanceBetweenUsers,
  calculateMatch,
  getLocalStorageVariable,
  postDataToAPI,
} from "../Shared/shared";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import MpesaPaymentModal from "../components/MpesaPaymentModal";
import ViewConnectModal from "../components/ViewConnectModal";
import IdVerificationModal from "../components/IdVerificationModal";
import { server } from "../configs/configs";

const profilePicFolder = `${server}/profilePics`;

const Dashboard = () => {
  const navigate = useNavigate();

  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sessionType, setSessionType] = useState("");
  const [sessionDetails, setSessionDetails] = useState(null);
  const userEmail = getLocalStorageVariable("userEmail");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [connectId, setConnectId] = useState("");
  const [connectEmail, setConnectEmail] = useState("");
  const [connectPhoneNumber, setConnectPhoneNumber] = useState("");
  const [regType, setRegType] = useState("");
  const [showViewConnectModal, setShowViewConnectModal] = useState(false);
  const [showIdVerificationModal, setShowIdVerificationModal] = useState(false);
  const [matchPercentages, setMatchPercentages] = useState({});
  const [loading, setLoading] = useState(false); // Loading state
  const [page, setPage] = useState(1); // Page state for pagination
  const [hasMore, setHasMore] = useState(true); // To check if there are more users to load

  const handleOpenViewConnectModal = () => setShowViewConnectModal(true);
  const handleCloseViewConnectModal = () => setShowViewConnectModal(false);

  const handleOpenIdVerificationModal = () => setShowIdVerificationModal(true);
  const handleCloseIdVerificationModal = () =>
    setShowIdVerificationModal(false);

  const checkUserDetails = async (email) => {
    const formData = new FormData();
    formData.append("email", email);
    const response = await postDataToAPI("/fetchProfileData.php", formData);
    if (response) {
      setSessionDetails(response);
      fetchUsers(response);
    }
  };

  const fetchUsers = async (sessionDetails) => {
    if (!sessionDetails) {
      console.error("Session details are not available.");
      return;
    }

    const MyRegType = sessionDetails.registrationType;
    setSessionType(MyRegType);
    const formData = new FormData();
    if (MyRegType === "house-help" || MyRegType === "chef") {
      formData.append("registrationType", "home-owner");
    } else {
      formData.append("registrationType", "rest");
    }

    try {
      const response = await postDataToAPI(
        "/fetchSpecificCategoryOfUsers.php",
        formData
      );
      if (response && Array.isArray(response)) {
        const sessionCoordinates = sessionDetails.coordinates
          ? sessionDetails.coordinates
              .slice(1, -1)
              .split(",")
              .map((coord) => parseFloat(coord.trim()))
          : null;

        const usersWithDistances = response.map((user) => {
          const homeownerCoordinates = user.coordinates
            ? user.coordinates
                .slice(1, -1)
                .split(",")
                .map((coord) => parseFloat(coord.trim()))
            : null;

          const distance =
            sessionCoordinates && homeownerCoordinates
              ? calculateDistanceBetweenUsers(
                  sessionCoordinates[0],
                  sessionCoordinates[1],
                  homeownerCoordinates[0],
                  homeownerCoordinates[1]
                )
              : Infinity;

          return {
            ...user,
            distance,
          };
        });

        usersWithDistances.sort((a, b) => a.distance - b.distance);
        setUsers(usersWithDistances);
        setFilteredUsers(usersWithDistances);
      } else {
        console.error("Unexpected response format:", response);
      }
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = users.filter(
      (user) =>
        user.fullName.toLowerCase().includes(value) ||
        user.religion?.toLowerCase().includes(value) ||
        user.gender?.toLowerCase().includes(value) ||
        user.selectedCountry?.toLowerCase().includes(value) ||
        user.address?.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };

  useEffect(() => {
    checkUserDetails(userEmail);
  }, [userEmail]);

  const handleViewProfile = (email) => {
    navigate("/ViewProfile", { state: { email, sessionType } });
  };

  const handleConnect = async (id, phoneNumber, email, registrationType) => {
    const formData = new FormData();
    formData.append("connectId", id);
    formData.append("userEmail", userEmail);
    const response = await postDataToAPI("/checkIfConnectPaid.php", formData);

    if (response === "Success") {
      setConnectId(id);
      setConnectEmail(email);
      setConnectPhoneNumber(phoneNumber);
      setRegType(registrationType);
      handleOpenViewConnectModal();
    } else {
      setConnectId(id);
      setConnectEmail(email);
      setConnectPhoneNumber(phoneNumber);
      setRegType(registrationType);
      setIsModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const displayContactInfo = async () => {
    handleOpenViewConnectModal();
    const formData = new FormData();
    formData.append("userMail", userEmail);
    formData.append("connectEmail", connectEmail);
    formData.append("connectPhoneNumber", connectPhoneNumber);

    const response = await postDataToAPI("/sendConnectEmails.php", formData);
    if (response === "Email Sent") {
      console.log("We have sent contact info to your email.");
    }
  };

  const onIdVerificationSuccess = () => {
    displayContactInfo();
  };

  const handleOnSuccess = async () => {
    const verifyData = new FormData();
    verifyData.append("userMail", userEmail);
    const verificationRes = await postDataToAPI(
      "/IPRS/checkIfUserIdIsVerified.php",
      verifyData
    );
    if (verificationRes === "Success") {
      const updateIdData = new FormData();
      updateIdData.append("connectId", connectId);
      updateIdData.append("userEmail", userEmail);

      const updateRes = await postDataToAPI(
        "/IPRS/updateIdListArray.php",
        updateIdData
      );
      if (updateRes.success) {
        console.log("Updated Id Records");
      }

      displayContactInfo();
    } else {
      handleOpenIdVerificationModal();
    }
  };

  useEffect(() => {
    const fetchMatchPercentages = async () => {
      const newMatchPercentages = {};

      for (const user of filteredUsers) {
        const match = await calculateMatch(
          user.email,
          user.registrationType,
          sessionType
        );
        newMatchPercentages[user.email] = match;
      }

      setMatchPercentages(newMatchPercentages);
    };

    fetchMatchPercentages();
  }, [filteredUsers]);

  // Handle scrolling to load more users when user reaches bottom
  const handleScroll = () => {
    const bottom =
      window.innerHeight + document.documentElement.scrollTop ===
      document.documentElement.offsetHeight;
    if (bottom && !loading && hasMore) {
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        fetchUsers(sessionDetails, newPage);
        return newPage;
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  return (
    <>
      <NavBar />
      <div className="dashboard-container">
        <div className="dashboard-contents">
          {sessionType === "house-help" || sessionType === "chef" ? (
            <h3>Home Owners</h3>
          ) : sessionType === "home-owner" ? (
            <h3>House Helps and Chefs</h3>
          ) : null}

          {/* Search Bar */}
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearch}
            className="search-bar"
          />
          <div className="homeowner-list">
            {filteredUsers.map((user) => (
              <li key={user.id} className="homeowner-item">
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div className="profile-picture-container">
                      <img
                        src={
                          `${profilePicFolder}/${user.profilePic}` ||
                          `${profilePicFolder}/defaultPIC.jpg}`
                        }
                        alt=""
                        className="profile-picture"
                      />
                    </div>
                  </div>
                  <div style={{ marginTop: -10, marginBottom: -20 }}>
                    <h4>{user.fullName}</h4>
                    <p>
                      Match: {matchPercentages[user.email] || "Calculating..."}%
                    </p>
                    <p>{user.address}</p>
                    <p style={{ color: "#642D92" }}>
                      About:{" "}
                      {user.distance !== Infinity
                        ? `${user.distance.toFixed(2)} KM from you`
                        : "Unknown"}
                    </p>
                  </div>
                </div>
                <div className="links-container">
                  {" "}
                  {/* Wrapper for the links */}
                  {sessionType === "house-help" || sessionType === "chef" ? (
                    <>
                      <Button
                        onClick={() =>
                          handleConnect(
                            user.id,
                            user.phoneNumber,
                            user.email,
                            "house-help"
                          )
                        }
                        className="connect-button"
                      >
                        Connect
                      </Button>
                      <Button
                        onClick={() => handleViewProfile(user.email)}
                        className="connect-button"
                        style={{
                          color: "#fff",
                          backgroundColor: "purple",

                          borderRadius: "5px",
                        }}
                      >
                        View
                      </Button>
                    </>
                  ) : sessionType === "home-owner" ? (
                    <>
                      <Button
                        onClick={() =>
                          handleConnect(
                            user.id,
                            user.phoneNumber,
                            user.email,
                            "home-owner"
                          )
                        }
                        className="connect-button"
                      >
                        Connect
                      </Button>
                      <Button
                        onClick={() => handleViewProfile(user.email)}
                        className="connect-button"
                        style={{
                          color: "#fff",
                          backgroundColor: "purple",
                          borderRadius: "5px",
                        }}
                      >
                        View
                      </Button>
                    </>
                  ) : null}
                </div>
              </li>
            ))}
          </div>
        </div>
      </div>

      <MpesaPaymentModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSuccess={handleOnSuccess}
        connectId={connectId}
        regType={regType}
      />

      <ViewConnectModal
        isOpen={showViewConnectModal}
        onClose={handleCloseViewConnectModal}
        connectEmail={connectEmail}
        connectPhoneNumber={connectPhoneNumber}
      />

      <IdVerificationModal
        isOpen={showIdVerificationModal}
        onClose={handleCloseIdVerificationModal}
        onIdVerificationSuccess={onIdVerificationSuccess}
        connectId={connectId}
      />
    </>
  );
};

export default Dashboard;
