import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../CSS/Login.css";
import "../CSS/Commons.css";
import {
  getUserLocation,
  handleGeolocationError,
  postDataToAPI,
  setLocalStorageVariable,
} from "../Shared/shared";
import Swal from "sweetalert2";
import { Navigation2 } from "../../landingPage/components/navigation2";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();

  const startSession = async (email) => {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("action", "newSession");
    const response = await postDataToAPI("/sessions.php", formData);
    if (response === 1) {
      // console.log("Session Started.........");
    } else {
      // console.log("Response sesion.........", response);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform login logic here
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    const response = await postDataToAPI("/signIn.php", formData);

    if (response === "Success") {
      // Start session
      startSession(email);
      // Save email to local storage
      setLocalStorageVariable("userEmail", email);

      // Attempt to get user location
      try {
        const { latitude, longitude } = await getUserLocation();

        // Update location to current location
        const locationFormData = new FormData();
        locationFormData.append(
          "coordinates",
          JSON.stringify([latitude, longitude])
        );
        locationFormData.append("userEmail", email);

        const locationResponse = await postDataToAPI(
          "/updateUserLocation.php",
          locationFormData
        );

        if (locationResponse !== "Success") {
          // console.log("Failed to update location.");
        }
      } catch (error) {
        handleGeolocationError(error); // Optional: Show error if needed
      }

      // Show success message and navigate
      Swal.fire({
        title: "Success!",
        text: "Login successful! Redirecting to Dashboard",
        icon: "success",
        timer: 3000, // 3 seconds
        showConfirmButton: false,
        willClose: () => {
          navigate("/dashboard");
        },
      });
    } else {
      setErrorMessage(response);
    }
  };

  return (
    <div className="login-container">
      <Navigation2 />
      <div className="login-box">
        {/* <h2 className="system-name">Nannies Desk</h2> */}
        <form className="login-form" onSubmit={handleSubmit}>
          <div className="logo-container">
            <img src="/Assets/nanniesDesk.jpg" alt="Logo" className="logo" />
          </div>
          <h2 className="login-title">Login</h2>
          <div className="form-groups">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-input"
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-groups">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-input"
              placeholder="Enter your password"
              required
            />
          </div>{" "}
          <br></br>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
          {successMessage && (
            <p className="success-message">{successMessage}</p>
          )}
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        <div className="login-links">
          <Link to="/register" className="link-register" id="link">
            Create an account
          </Link>
          <Link to="/reset-password" className="link-resetPassword" id="link">
            Forgot Password?
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
